import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import TeachersDetails from "../../components/TeachersDetails";
import anagha from "../../assets/images/profile/jinal.jpg";
const data = {
  name: "Jinal Maniar",
  description:
    "Jinal is a dedicated Early Years Educator with over a decade of experience. Her journey includes roles as a pre-primary teacher and a preschool Co-ordinator, showcasing her versatility and commitment to early childhood education. Known for her innovative teaching techniques, Jinal not only captivates young learners but also mentors fellow teachers, guiding them in employing effective methodologies and classroom strategies. Her passion for education and skill in fostering a nurturing learning environment make her a valuable asset in the field of early childhood education."};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={anagha} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Early Years Educator</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
